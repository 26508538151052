import "./Footer.css";

const Footer = () => {
  return (
    <footer className="center-text footer">
      © Designed & Built by Howie Wang, 2025
    </footer>
  );
};

export default Footer;
